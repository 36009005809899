
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { saveLiveActivity, getLiveActivityList, getLiveRoomUserList, setLiveRoomUserBlock, setLiveActivityStatus, getLiveRoomMessageList, getLiveRoomActivityUserList, addLiveActivityWhiteUser, setLiveActivityLiveStatus } from "@/api/request/activity";

//组件
@Component({
  name: "KolManager",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //零时数据
    id_str: "", //ID
    blogger_id_str: "", // 连线主播ID
    live_user_id_str: "", // 直播用户ID

    //请求数据
    id: 0, //ID
    status: -1, //状态
    title: "", // 活动标题
    country: "", //归属国家
    blogger_id: 0, // 连线主播ID
    live_user_id: 0, // 直播用户ID
  };

  //状态列表
  private statusList: any[] = [
    { status: -1, name: "全部状态" },
    { status: 0, name: "草稿" },
    { status: 1, name: "启用" },
    { status: 2, name: "停止" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //其他数据
    this.listQuery.id = Number(this.listQuery.id_str) > 0 ? Number(this.listQuery.id_str) : undefined;
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str) > 0 ? Number(this.listQuery.blogger_id_str) : undefined;
    this.listQuery.live_user_id = Number(this.listQuery.live_user_id_str) > 0 ? Number(this.listQuery.live_user_id_str) : undefined;

    //获取数据
    const { data } = await getLiveActivityList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private handleAdd(): void {
    //数据赋值
    this.form = {
      //临时数据
      blogger_id_str: "", //连线博主ID
      live_user_id_str: "", //直播用户ID

      //数据
      id: undefined, //ID
      icon: "", //活动图标
      title: "", //活动标题
      cover: "", //活动banner
      country: "", //归属国家
      content: "", //活动介绍
      live_icon: "", //直播中图标
      blogger_id: 0, //连线博主ID
      live_user_id: 0, //直播用户ID
    };
    this.picIconFileList = []; //图标图片文件
    this.picCoverFileList = []; //封面图片文件
    this.picLiveIconFileList = []; //直播图片文件

    //显示界面
    this.dialogShow = true;
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      blogger_id_str: String(row.blogger_id), //连线博主ID
      live_user_id_str: String(row.live_user_id), //直播用户ID

      //数据
      id: row.id, //ID
      icon: row.icon, //活动图片
      title: row.title, //活动标题
      cover: row.cover, //活动图片
      country: row.country, //归属国家
      content: row.content, //活动介绍
      live_icon: row.live_icon, //直播中图标
      blogger_id: row.blogger_id, //连线博主ID
      live_user_id: row.live_user_id, //直播用户ID
    };
    this.picIconFileList = [{ url: row.icon }]; //图标图片文件
    this.picCoverFileList = [{ url: row.cover }]; //封面图片文件
    this.picLiveIconFileList = [{ url: row.live_icon }]; //直播图片文件

    //显示界面
    this.dialogShow = true;
  }

  //修改状态
  private async changeStatus(row: any) {
    //显示等待
    this.listLoading = true;

    //设置状态
    await setLiveActivityStatus({ id: row.id, status: row.status == 1 ? 2 : 1 });

    //隐藏等待
    setTimeout(() => {
      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //修改直播状态
  private async changeLiveStatus(row: any) {
    //显示等待
    this.listLoading = true;

    //设置状态
    await setLiveActivityLiveStatus({ id: row.id, status: row.live_status == 1 ? 0 : 1 });

    //隐藏等待
    setTimeout(() => {
      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //处理用户列表
  private handleShowUserList(row: any): void {
    //显示界面
    this.userDialogShow = true;

    //数据赋值
    this.userListQuery.room_id = row.room_id;

    //获取数据
    this.getUserList();
  }

  //处理消息列表
  private handleShowMessageList(row: any): void {
    //显示界面
    this.messageDialogShow = true;

    //数据赋值
    this.messageListQuery.room_id = row.room_id;

    //获取数据
    this.getMessageList();
  }

  //处理用户列表
  private handleShowActivityUserList(row: any): void {
    //显示界面
    this.activityUserDialogShow = true;

    //数据赋值
    this.activityUserListQuery.activity_id = row.id;

    //获取数据
    this.getActivityUserList();
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //------------------------------ 添加/编辑活动 -----------------------------
  //定义变量
  private getHeads = getHeaders(); //获取抬头
  private getAction = this.getApi(); //获取API
  private picIconFileList: any[] = []; //图片文件
  private picCoverFileList: any[] = []; //图片文件
  private picLiveIconFileList: any[] = []; //图片文件
  private dialogShow: boolean = false; //显示随画框
  private dialogLoading: boolean = false; //对话框等待

  //表单数据
  private form: any = {
    //临时数据
    blogger_id_str: "", //连线博主ID
    live_user_id_str: "", //直播用户ID

    //数据
    id: undefined, //ID
    icon: "", //活动图标
    title: "", //活动标题
    cover: "", //活动banner
    country: "", //归属国家
    content: "", //活动介绍
    live_icon: "", //直播中图标
    blogger_id: 0, //连线博主ID
    live_user_id: 0, //直播用户ID
  };

  //获取APi
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //是否创建
  private isCreate(): boolean {
    return this.form.id == undefined ? true : false;
  }

  //创建活动
  private async createHandle() {
    //数据处理
    if (this.form.title == "") return this.$message.error("请输入活动标题");
    if (this.form.icon == "") return this.$message.error("请输上传活动图标");
    if (this.form.cover == "") return this.$message.error("请输上传封面图片");
    if (this.form.content == "") return this.$message.error("请输入活动介绍");
    if (this.form.live_icon == "") return this.$message.error("请输上传直播中图标");
    if (Number(this.form.blogger_id_str) == 0) return this.$message.error("请输入正确的连线博主ID");
    if (Number(this.form.live_user_id_str) == 0) return this.$message.error("请输入正确的直播用户ID");

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.form.blogger_id = Number(this.form.blogger_id_str); //连线博主ID
    this.form.live_user_id = Number(this.form.live_user_id_str); //直播用户ID

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;
    }, 1.5 * 1000);

    //保存活动
    await saveLiveActivity(this.form);

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;

      //隐藏界面
      this.dialogShow = false;

      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //更新活动
  private async updataHandle() {
    //数据处理
    if (this.form.title == "") return this.$message.error("请输入活动标题");
    if (this.form.icon == "") return this.$message.error("请输上传活动图标");
    if (this.form.cover == "") return this.$message.error("请输上传封面图片");
    if (this.form.content == "") return this.$message.error("请输入活动介绍");
    if (this.form.live_icon == "") return this.$message.error("请输上传直播中图标");
    if (Number(this.form.blogger_id_str) == 0) return this.$message.error("请输入正确的连线博主ID");
    if (Number(this.form.live_user_id_str) == 0) return this.$message.error("请输入正确的直播用户ID");

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.form.blogger_id = Number(this.form.blogger_id_str); //连线博主ID
    this.form.live_user_id = Number(this.form.live_user_id_str); //直播用户ID

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;
    }, 1.5 * 1000);

    //保存活动
    await saveLiveActivity(this.form);

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;

      //隐藏界面
      this.dialogShow = false;

      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //图片图片上传
  private handlePicBeforeUploadCommon(file: any) {
    //定义配置
    const index = file.name.lastIndexOf(".");
    const isLt2M = file.size / 1024 / 1024 < 5;
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];

    //资源过大
    if (!isLt2M) {
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出5M",
        type: "warning",
        duration: 2000,
      });
      return false;
    }
    //格式不支持
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除图标图片
  private handleIconPicRemove(file: any, fileList: any): void {
    //清空数据
    this.form.icon = "";
    this.picIconFileList = fileList;
  }

  //移除封面图片
  private handleCoverPicRemove(file: any, fileList: any): void {
    //清空数据
    this.form.cover = "";
    this.picCoverFileList = fileList;
  }

  //移除直播图片
  private handleLiveIconPicRemove(file: any, fileList: any): void {
    //清空数据
    this.form.live_icon = "";
    this.picLiveIconFileList = fileList;
  }

  //活动图标图片上传成功
  private handleIconPicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picIconFileList = fileList;
    this.form.icon = response.data.images[0];
  }

  //封面图片上传成功
  private handleCoverPicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picCoverFileList = fileList;
    this.form.cover = response.data.images[0];
  }

  //直播图标图片上传成功
  private handleLiveIconPicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picLiveIconFileList = fileList;
    this.form.live_icon = response.data.images[0];
  }

  //---------------------------- 图片加载 ----------------------------
  //定义变量
  private dialogVisible = false; //显示状态
  private dialogImageUrl: any = null; //图片地址

  //点击已上传图片
  private handlePreviewCommon(file: any) {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示界面
    this.dialogVisible = true;
  }

  //-------------------------------- 活动用户列表 --------------------------------
  //定义变量
  private activityUserList: any[] = [];
  private activityUserListTotal: number = 0;
  private activityUserDialogShow: boolean = false; //显示随画框
  private activityUserDialogLoading: boolean = false; //对话框等待

  //列表请求参数
  private activityUserListQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "", //用户ID

    //上传数据
    user_type: 0, //用户类型
    nick_name: "", //用户昵称
    activity_id: 0, //活动ID
    user_id: undefined, //用户ID
  };

  //用户类型
  private userTypeList: any[] = [
    { type: 0, name: "已报名用户" },
    { type: 1, name: "推广白名单用户" },
    { type: 2, name: "自定义白名单" },
  ];

  //添加自定义白名单
  private handleShowAddWhitelist(): void {
    //数据赋值
    this.tagInput = "";
    this.tagInputMore = "";
    this.addWhitelistQuery.user_ids = [];
    this.addWhitelistQuery.user_ids_str = [];
    this.addWhitelistQuery.activity_id = this.activityUserListQuery.activity_id;

    //显示界面
    this.addWhitelistDialogShow = true;
  }

  //获取用户列表
  private async getActivityUserList() {
    //显示等待
    this.activityUserDialogLoading = true;

    //数据赋值
    this.activityUserListQuery.user_id = Number(this.activityUserListQuery.user_id_str) == 0 ? undefined : Number(this.activityUserListQuery.user_id_str);

    //获取数据
    const { data } = await getLiveRoomActivityUserList(this.activityUserListQuery);

    //数据赋值
    this.activityUserList = data.list;
    this.activityUserListTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.activityUserDialogLoading = false;
    }, 0.5 * 1000);
  }

  //取消按钮
  private btnActivityUserCancel(): void {
    //隐藏界面
    this.activityUserDialogShow = false;

    //获取列表
    this.getActivityUserList();
  }

  //处理查询
  private handleActivityUserSearch(): void {
    //数据赋值
    this.activityUserListQuery.page = 1;

    //获取列表
    this.getActivityUserList();
  }

  //------------------------------- 添加白名单 -------------------------------

  //定义数据
  private tagInput: string = ""; //用户ID文本
  private tagInputMore: string = ""; //批量添加ID文本
  private addWhitelistDialogShow: boolean = false; //显示随画框
  private addWhitelistDialogLoading: boolean = false; //对话框等待

  //列表请求参数
  private addWhitelistQuery: any = {
    //临时数据
    user_ids_str: [],

    //数据赋值
    user_ids: [], // 用户ID列表
    activity_id: 0, // 活动ID
  };

  //添加目标用户
  private addTag(type: any): void {
    //批量添加
    if (type === "more") {
      //定义变量
      let errorList: any = [];

      //数据处理
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item) => {
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());
          this.addWhitelistQuery.user_ids_str.push(item.trim());
          this.tagInputMore = ""; // 清空输入框
        });

      //显示异常提示
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      //数据处理
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        this.addWhitelistQuery.user_ids_str.push(this.tagInput.trim());
        this.tagInput = ""; // 清空输入框
      }
      //异常处理
      else {
        this.$message.error("请输入正确形式的ID");
        this.tagInput = "";
      }
    }
  }

  //移除目标用户
  private removeTag(index: any): void {
    this.addWhitelistQuery.user_ids_str.splice(index, 1);
  }

  //取消按钮
  private btnAddWhitelistCancel(): void {
    //隐藏界面
    this.addWhitelistDialogShow = false;
  }

  //处理添加白名单
  private async handleAddWhitelist() {
    //判断返回
    if (this.addWhitelistQuery.user_ids_str.length == 0) return this.$message.error("请添加用户ID");

    //显示等待
    this.addWhitelistDialogLoading = true;

    //数据赋值
    for (var i: number = 0; i < this.addWhitelistQuery.user_ids_str.length; i++) {
      this.addWhitelistQuery.user_ids.push(Number(this.addWhitelistQuery.user_ids_str[i]));
    }

    //延迟隐藏
    setTimeout(() => {
      //隐藏界面
      this.addWhitelistDialogLoading = false;
    }, 1.5 * 1000);

    //添加自定义ID
    await addLiveActivityWhiteUser(this.addWhitelistQuery);

    //隐藏等待
    setTimeout(() => {
      //隐藏界面
      this.addWhitelistDialogShow = false;

      //隐藏界面
      this.addWhitelistDialogLoading = false;

      //判断刷新界面
      if (this.activityUserListQuery.user_type == 2) this.getActivityUserList();
    }, 0.5 * 1000);
  }

  //-------------------------------- 用户列表 --------------------------------
  //定义变量
  private userList: any[] = [];
  private userListTotal: number = 0;
  private userDialogShow: boolean = false; //显示随画框
  private userDialogLoading: boolean = false; //对话框等待

  //列表请求参数
  private userListQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //上传数据
    room_id: "",
    nick_name: "",
  };

  //获取用户列表
  private async getUserList() {
    //显示等待
    this.userDialogLoading = true;

    //获取数据
    const { data } = await getLiveRoomUserList(this.userListQuery);

    //数据赋值
    this.userList = data.list;
    this.userListTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.userDialogLoading = false;
    }, 0.5 * 1000);
  }

  //取消按钮
  private btnUserCancel(): void {
    //隐藏界面
    this.userDialogShow = false;
  }

  //处理用户查询
  private handleUserSearch(): void {
    //数据赋值
    this.userListQuery.page = 1;

    //获取数据
    this.getUserList();
  }

  //禁言 -->
  private async handleBan(row: any) {
    //显示等待
    this.userDialogLoading = true;

    //获取数据
    await setLiveRoomUserBlock({ room_id: this.userListQuery.room_id, status: 1, user_ids: [row.user_id] });

    //隐藏等待
    setTimeout(() => {
      //获取数据
      this.getUserList();
    }, 0.5 * 1000);
  }

  //踢出 -->
  private async handleOut(row: any) {
    //显示等待
    this.userDialogLoading = true;

    //获取数据
    await setLiveRoomUserBlock({ room_id: this.userListQuery.room_id, status: 2, user_ids: [row.user_id] });

    //隐藏等待
    setTimeout(() => {
      //获取数据
      this.getUserList();
    }, 0.5 * 1000);
  }

  //解除禁言 -->
  private async handleUnBan(row: any) {
    //显示等待
    this.userDialogLoading = true;

    //获取数据
    await setLiveRoomUserBlock({ room_id: this.userListQuery.room_id, status: -1, user_ids: [row.user_id] });

    //隐藏等待
    setTimeout(() => {
      //获取数据
      this.getUserList();
    }, 0.5 * 1000);
  }

  //-------------------------------- 消息列表 --------------------------------
  //定义变量
  private messageList: any[] = [];
  private messageListTotal: number = 0;
  private messageDialogShow: boolean = false; //显示随画框
  private messageDialogLoading: boolean = false; //对话框等待

  //列表请求参数
  private messageListQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "",

    //上传数据
    room_id: "",
    user_id: 0,
  };

  //获取用户列表
  private async getMessageList() {
    //显示等待
    this.messageDialogLoading = true;

    //数据赋值
    this.messageListQuery.user_id = Number(this.messageListQuery.user_id_str) > 0 ? Number(this.messageListQuery.user_id_str) : undefined;

    //获取数据
    const { data } = await getLiveRoomMessageList(this.messageListQuery);

    //数据赋值
    this.messageList = data.list;
    this.messageListTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.messageDialogLoading = false;
    }, 0.5 * 1000);
  }

  //取消按钮
  private btnMessageCancel(): void {
    //隐藏界面
    this.messageDialogShow = false;
  }

  //处理用户查询
  private handleMessageSearch(): void {
    //数据赋值
    this.messageListQuery.page = 1;

    //获取数据
    this.getMessageList();
  }
}
